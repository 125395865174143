import React, { ReactNode, useEffect, useState } from "react";
import { useAuthStore } from "modules/Auth/authStore";
import { getSubDomain, isBlueBirdURL } from "lib/host";
import { useGetSubdomainData } from "modules/Tenant/homeTenantApi";
import { isBrowser } from "lib/ssr";
import { Image } from "components/Image/Image";
import { Logo } from "components/Logo";
import { useSystemStore } from "components/PageHeader/systemStore";
import Images from "next/image";

export const HomeLogo: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const theme = useSystemStore((state) => state.theme);
  const { loginState, currentUser, tenantData, setTenantData } = useAuthStore(
    (state) => ({
      loginState: state.loginState,
      currentUser: state.currentUser,
      tenantData: state.tenantData,
      setTenantData: state.setTenantData,
    })
  );
  // @ts-ignore
  const getSubdomainAPI = useGetSubdomainData();

  /* Hit API Referral upon this referral user */
  const listReferralToShowLogo = ["venteny", "demo-hris", "zicare", "bluebird"];
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;
  const isBlueBirdTenant =
    typeof window !== "undefined" && isBlueBirdURL(window.location.hostname);

  useEffect(() => {
    if (listReferralToShowLogo.includes(currentUser?.referral ?? subDomain)) {
      getSubdomainAPI.doFetch(
        {
          slug: currentUser?.referral ?? subDomain,
        },
        {
          onSuccess(data) {
            /// Save to zustand
            setTenantData(data["data"]);
            // @ts-ignore
            // setImageUrl(
            //   "https://s.gokampus.com/cdn-cgi/image/,quality=75/business/logo-summarecon-agung.png"
            // );
          },
        }
      );
    }
  }, []);

  // Variable to make sure the window
  // that used in subDomain variable is ready
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient &&
    (subDomain == "geekhunter" || currentUser?.referral == "geekhunter") ? (
    <div className="flex gap-12 items-center">
      <Logo scale={1} useWhite={theme == "dark"} />
      <div className="flex flex-col md:gap-4">
        <p className="text-c1 font-light">in partnership with</p>
        <Image
          width={100}
          // height={36}
          src={
            "https://staging-static.gokampus.com/course-institutions/xfnWua6aZk3FVTIWpSu1fdezB8psLcSaqxQ0VDmm.png"
          }
          alt="geekhunter"
          className="object-contain object-center"
        />
      </div>
    </div>
  ) : tenantData?.image_url != null &&
    tenantData?.image_url != "" &&
    listReferralToShowLogo.includes(currentUser?.referral ?? subDomain) &&
    !isBlueBirdTenant ? (
    <div className="flex gap-12">
      <Image
        width={120}
        // height={36}
        src={tenantData?.image_url}
        alt={tenantData?.name}
        className="object-contain object-center"
      />
      <div>
        <p className="text-c1 font-light">Powered by</p>
        <Logo scale={1.6} useWhite={theme == "dark"} />
      </div>
    </div>
  ) : isBlueBirdTenant ? (
    <Images
      src={
        theme == "dark"
          ? "/images/logo-bluebird-dark.png"
          : "/images/logo-bluebird-light.png"
      }
      alt="Blue Bird"
      width={120}
      height={27}
    />
  ) : (
    <Logo useWhite={theme == "dark"} />
  );
};
